
import React, { Suspense, useRef } from 'react'
import { Canvas } from '@react-three/fiber'
import { OrbitControls, Stage } from '@react-three/drei'
import { Model } from './Model'
import timeAgo from 'time-ago'
import classNames from 'classnames'
import delay from 'delay'
import Logo from './assets/logo.png'
// import city from '@pmndrs/assets/hdri/city.exr'

const INDEX_FILE_URL = "https://arcadia-img.s3.amazonaws.com/photo-index.json"
const NO_CURRENT_IMAGES = 25
const IMAGE_CHANGE_INTERVAL_SEC = 20
const IMAGE_FETCH_INTERVAL_SEC = 60

export default class Main extends React.Component {
  state = {
    imageName: '1434W,20S',
    imageTimestamp: -1,
    imageMinutesAgo: 0,
    windSpeed: 34,
    clouds: 24,
    chanceOfRain: 12,
    timeOfDay: '1:13 AM',
    imgSrc: 'https://arcadia-img.s3.amazonaws.com/2021-5-13-1-17-1-NPC-3.png',
    firstLoadComplete: false,
    loading: true,
    photoIndex: NO_CURRENT_IMAGES - 1,
    currentPhotos: []
  }

  fetchData = async () => {
    let data = await fetch(INDEX_FILE_URL)
    let json = await data.json()

    // console.log('Data is: ', json)

    // Actually most recent
    let sorted = Object.values(json).sort((a, b) => {
      return -Math.sign(a.timestamp - b.timestamp)
    }).slice(0, NO_CURRENT_IMAGES)

    this.setState({ currentPhotos: sorted, firstLoadComplete: true })
    // let mostRecent = Object.values(json).reduce((acc, el) => {
    //   if (el.timestamp > acc.timestamp) acc = el
    //   return acc
    // }, { timestamp: 0 })
  }

  changeImage = async () => {
    let newPhotoIndex = this.state.photoIndex - 1
    if (newPhotoIndex < 0) newPhotoIndex += this.state.currentPhotos.length

    let currentImage = this.state.currentPhotos[newPhotoIndex]

    if (currentImage.srcEnhanced != this.state.imgSrc) {
      // console.log('Most recent: ', mostRecent)      
      try {
        await new Promise((res, rej) => {
          const img = new Image()
          img.onload = () => {
            res()
          }
          img.onerror = () => {
            console.log("Failed to load...")
            rej()
          }
          img.src = currentImage.srcEnhanced
        })  
      } catch (e) {
        console.error(e)
        console.error("Failed to retrieve image...")
        return
      }

      this.setState({ loading: true })
      await delay(750)

      console.log("New image: ", currentImage.name)

      this.setState({
        imgSrc: currentImage.srcEnhanced,
        imageTimestamp: currentImage.timestamp,
        imageMinutesAgo: timeAgo.ago(currentImage.timestamp),
        imageName: currentImage.name,
        windSpeed: currentImage.windSpeed,
        clouds: currentImage.cloudCoverage,
        timeOfDay: currentImage.timeOfDay,
        chanceOfRain: currentImage.chanceOfRain,
        photoIndex: newPhotoIndex
      })

      await delay(750)
      this.setState({ loading: false })
      
    }

  }

  async componentDidMount() {
    this.setState({
      imageMinutesAgo: timeAgo.ago(this.state.imageTimestamp)
    })

    this.agoTimeout = setInterval(() => {
      this.setState({
        imageMinutesAgo: timeAgo.ago(this.state.imageTimestamp)
      })  
    }, 1000)

    await this.fetchData()
    await this.changeImage()

    this.fetcherTimeout = setInterval(async () => {
      await this.fetchData()
    }, IMAGE_FETCH_INTERVAL_SEC * 1000)

    this.changeTimeout = setInterval(async () => {
      await this.changeImage()
    }, IMAGE_CHANGE_INTERVAL_SEC * 1000)
  }

  componentWillUnmount() {
    clearInterval(this.agoTimeout)
    clearInterval(this.fetcherTimeout)
    clearInterval(this.changeTimeout)
  }

  formatParam = (s) => {
    while (s.length < 13) s = " " + s
    return s
  }

  formatParamRight = (s) => {
    while (s.length < 20) s = s + " "
    return s
  }

  render() {
    // const ref = useRef()
    // const city = import('@pmndrs/assets/hdri/city.exr').then((module) => module.default)
    const { imageMinutesAgo, imgSrc, imageName, firstLoadComplete, windSpeed, clouds, timeOfDay, loading } = this.state
    const imgCls = classNames({
      "main-image": true,
      "hideable": true,
      "hidden": (!firstLoadComplete || loading)
    })
  
    return (
      <div className="root-container">
        {/* <img src="/2022-6-14-0-47-15-NPC-2.png" className="main-image"/> */}
        <img src={imgSrc} className={imgCls}/>
        <img src={Logo} className="logo-image"/>
        {/* <Canvas shadows dpr={[1, 2]} camera={{ position: [-100, -10, 0], fov: 50 }}>
          <Suspense fallback={null}>
            <Stage preset="rembrandt" intensity={0.5} adjustCamera={false}>
              <Model />
            </Stage>
          </Suspense>
          <OrbitControls autoRotate enablePan={false} enableRotate={false} enableZoom={false} rotation={[0, 0, 0]} minDistance={1.25} maxDistance={1.25} />
        </Canvas> */}
        <div className="info">
          <div className="info-left">
            <span className="mhtirogla">Arcadia</span>
            <span className="tangier">&nbsp;&nbsp;Now</span>
          </div>
          
          { firstLoadComplete && 
            <div>
              <span className="tangier">On Display: </span>
              <span className="mhtirogla">{imageName}</span>
              <span className="tangier">, photographed </span>
              <span className="mhtirogla">&nbsp;{imageMinutesAgo}</span>
              <span className="tangier">, at simulated time</span>
              <span className="mhtirogla">&nbsp;{timeOfDay}.</span>
              <span className="tangier">&nbsp;There is a</span>
              <span className="mhtirogla">&nbsp;{clouds}% </span>
              <span className="tangier">cloud coverage, and the wind speed is</span>
              <span className="mhtirogla">&nbsp;{windSpeed}KPH.</span>
            </div>
          }
        </div>
      </div>
    )  
  }
}